<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-30'">
			<div class="section__top-left"></div>
		</div>

		<div class="section__body">
			<div class="contacts_block">
				<div class="contacts__call">
					<AppText
						:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
						weight="600"
						:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
						class="font_Hsemibold mb-50"
						>{{ $t("contacts") }}</AppText
					>
					<div class="call_item">
						<div class="d-flex align-center">
							<img src="@/assets/icons/call-icons.svg" class="mr-20" alt="" />
							<AppText
								:size="isMobileSmall ? 16 : isMobile ? 19 : 20"
								weight="600"
								:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
								class="font_Hsemibold"
								>+998 71 203 25 55</AppText
							>
						</div>
					</div>
					<div class="call_item">
						<div class="d-flex align-center">
							<img src="@/assets/icons/maps-icons.svg" class="mr-20" alt="" />
							<AppText
								:size="isMobileSmall ? 16 : isMobile ? 19 : 20"
								weight="600"
								:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
								class="font_Hsemibold"
								>{{ $t("cooperAdress") }}</AppText
							>
						</div>
					</div>
					<div class="call_item">
						<div class="d-flex align-center">
							<img src="@/assets/icons/email-icon.svg" class="mr-20" alt="" />
							<AppText
								:size="isMobileSmall ? 16 : isMobile ? 19 : 20"
								weight="600"
								:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
								class="font_Hsemibold"
								>manager@cooperation.uz</AppText
							>
						</div>
					</div>
					<div class="call_item call_item_links">
						<a target="_blank" href="https://www.facebook.com/cooperation.uz">
							<img src="../../assets/icons/facebook_contact.svg" class="mr-20" alt="" />
						</a>
						<a target="_blank" href="https://t.me/uz_cooperation">
							<img src="../../assets/icons/telegram_contact.svg" class="mr-20" alt="" />
						</a>
						<a target="_blank" href="https://www.instagram.com/cooperation_uz">
							<img src="../../assets/icons/instagram_contact.svg" class="mr-20" alt="" />
						</a>
						<a target="_blank" href="https://www.youtube.com/@cooperation_uz">
							<img src="../../assets/icons/youtube_contact.svg" class="mr-20" alt="" />
						</a>
					</div>
				</div>
				<div class="contacts_wrap">
					<div class="contacts__maps">
						<!-- <img src="@/assets/icons/maps_icon2.svg" class="mr-20 w-100 h-100" alt="" /> -->
						<iframe
							src="https://yandex.uz/map-widget/v1/?ll=69.236606%2C41.313085&z=20"
							type=""
							width="100%"
							height="100%"
							frameborder="0"
							scrolling="no"
							allowfullscreen="true"
						></iframe>
					</div>
					<div class="contacts__reg">
						<AppText
							:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
							weight="600"
							:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
							class="font_Hsemibold mb-50"
							>{{ $t("feedback2") }}</AppText
						>
						<div class="contacts_form mb-20">
							<div class="label_block mb-10">
								<label class="fs-14 color-text">{{ $t("yourName") }}</label>
							</div>
							<input type="text" :placeholder="$t('enter_name')" />
						</div>
						<div class="contacts_form mb-20">
							<div class="label_block mb-10">
								<label class="fs-14 color-text">{{ $t("phone_number") }}</label>
							</div>
							<input type="text" :placeholder="$t('enter_phone_number')" />
						</div>
						<div class="contacts_form mb-30">
							<div class="label_block mb-10">
								<label class="fs-14 color-text">{{ $t("eMail") }}</label>
							</div>
							<input type="text" :placeholder="$t('your_email')" />
						</div>
						<AppButton theme="main" sides="20" radius="8" height="40" font-size="16" class="">
							{{ $t("send") }}
						</AppButton>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import FormLabel from "../../components/shared-components/FormLabel";
import BlockWrap from "../../components/shared-components/BlockWrap";
import AppButton from "../../components/shared-components/AppButton";

export default {
	name: "index",
	components: { AppButton, BlockWrap, FormLabel, AppText },
	data() {
		return {};
	},
	methods: {},
};
</script>

<style lang="scss" scoped>
.call_item_links {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.call_item {
	margin-bottom: 30px;
	img {
		height: 52px;
	}
}
.contacts_block {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 100px;
	.contacts__call {
		width: 30%;
	}
	.contacts_wrap {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 60px;
		background: #f1f7fc;
		border-radius: 20px;
		padding: 20px;
		.contacts__maps {
			width: 580px;
			height: 451px;
			border-radius: 20px;
			background: #eee;
			overflow: hidden;
		}

		.contacts__reg {
			.contacts_form {
				.label_block {
					width: max-content;
					position: relative;
					::before {
						content: "*";
						width: 5px;
						height: 5px;
						position: absolute;
						right: -6px;
						top: 0;
						color: red;
					}
				}
				input {
					width: 100%;
					height: 48px;
					border-radius: 12px;
					padding: 15px 18px;
					border: none;
				}
				input::placeholder {
					color: #8699aa;
				}
			}
		}
	}
}
</style>
